<template>
  <div class="main-body">
    <header class="header-wrap">
      <div class="header">
        <img class="return" src="~images/dow-ceremony/right-arrow.png" alt="" @click="prevStep">
        <span class="title">{{registerTitle}}</span>
      </div>
    </header>
    <div v-if="!userinfoRegister" class="register-banner">
      <img src="~images/dow-ceremony/bitmap.png" alt="">
    </div>
    <div class="register-wrap">
      <ul v-if="!userinfoRegister" class="register-list">
        <li class="clearfix">
          <span class="left">真实姓名</span>
          <cube-input class="left" v-model="userinfo.username" :maxlength="10" placeholder="请输入真实姓名" type="text"></cube-input>
        </li>
        <li class="clearfix">
          <span class="left">手机号</span>
          <cube-input class="left" v-model="userinfo.mobilePhone" placeholder="请输入手机号" type="text"></cube-input>
        </li>
        <li class="clearfix">
          <span class="left">验证码</span>
          <cube-input class="left input-vcode" v-model="userinfo.vcode" placeholder="请输入验证码" type="text">
          </cube-input>
          <div class="right">
            <span v-if="sendCaptchaEnabled" class="get-code" @click="sendCaptcha()">{{getVcode}}</span>
            <span v-else class="after-get-code">{{counterTimer}}秒后获取</span>
          </div>
        </li>
        <li class="clearfix li-password">
          <span class="left">设置密码</span>
          <cube-input class="left input-password" :maxlength="18" v-model="userinfo.password" placeholder="请设置密码" :type="showPassword ? 'text' : 'password'"></cube-input>
          <div @click="toggleShowPassword" class="right eye-box">
            <span class="open-eye" v-if="showPassword"></span>
            <span class="close-eye" v-else></span>
          </div>
        </li>
      </ul>
      <ul v-if="userinfoRegister" class="register-list">
        <li class="li-title">
          <span class="title">完善信息</span>
        </li>
        <li class="clearfix">
          <span class="left">门店名称</span>
          <cube-input class="left" v-model="userinfo.garageName" :maxlength="20" placeholder="请输入门店名称" type="text"></cube-input>
        </li>
        <li class="clearfix li-selected-district">
          <span class="left">选择地区</span>
          <span class="left selected-district" :class="[selectedDistrictVisible?'':'unselected-district']" @click="showCityPicker">{{selectedDistrict}}</span>
          <div>
            <img :class="[districtPickerVisible?'top-arrow':'bottom-arrow']" src="~images/dow-ceremony/bottom-arrow.png" alt="" class="left icon-selected-district" @click="showCityPicker">
          </div>
        </li>
        <li class="clearfix">
          <span class="left">详细地址</span>
          <cube-input class="left" v-model="userinfo.detailedAddress" :maxlength="20" placeholder="请输入详细地址" type="text"></cube-input>
        </li>
      </ul>
      <div class="register-button">
        <cube-button v-if="!userinfoRegister" type="submit" class="btn-default" @click="nextStep">
          <span>下一步</span>
        </cube-button>
        <cube-button v-if="userinfoRegister" type="submit" class="btn-default" @click="submitted">
          <span>完成</span>
        </cube-button>
        <p class="remark">注册成功后即可参加“2018达欧超级盛典活动”</p>
      </div>

    </div>
  </div>
</template>

<script>
import { regionData } from 'element-china-area-data'
import { getVcodeToRegistered, checkVcode, garageRegistered } from 'api/user'
import md5 from 'js-md5'

export default {
  data () {
    return {
      registerTitle: '注册',
      userinfo: {
        username: '',
        mobilePhone: '',
        vcode: '',
        password: '',
        garageName: '',
        address: {
          province: '',
          city: '',
          district: ''
        },
        detailedAddress: ''
      },
      rules: {
        username: '',
        mobilePhone: '',
        vcode: '',
        password: '',
        garageName: '',
        address: {
          province: '',
          city: '',
          district: ''
        },
        detailedAddress: ''
      },
      getVcode: '获取验证码',
      counterTimer: 60,
      sendCaptchaEnabled: true,
      sending: false,
      showPassword: false,
      userinfoRegister: false,
      eye: true,
      selectedDistrict: '请选择地区',
      selectedDistrictVisible: false,
      districtPickerVisible: false,
      judgmentModel: '',
      managerUserId: ''
    }
  },
  methods: {
    initData () {
      let type = this.$route.query['type']
      this.judgmentModel = type
      this.managerUserId = this.$route.query['managerUserId']
    },
    sendCaptcha () {
      if (!this.userinfo.mobilePhone.trim()) {
        const toast = this.$createToast({
          txt: `手机号码不能为空`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!/^1\d{10}$/.test(this.userinfo.mobilePhone.trim())) {
        const toast = this.$createToast({
          txt: `请正确输入11位数字的手机号`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!this.sendCaptchaEnabled) {
        return
      }
      if (this.sending) {
        return
      }
      let params = {
        phone: this.userinfo.mobilePhone
      }
      this.sending = true
      getVcodeToRegistered(JSON.stringify(params)).then((res) => {
        this.sending = false
        let { result, desc } = res.data
        if (result !== 0) {
          const toast = this.$createToast({
            txt: `${desc}`,
            type: 'error',
            time: 2000
          })
          toast.show()
          return
        }
        this.sendCaptchaEnabled = false
      })
      let that = this
      this.sendCaptchaEnabled = false
      that.timer = setInterval(() => {
        if (this.counterTimer <= 0) {
          this.counterTimer = 60
          this.sendCaptchaEnabled = true
          this.getVcode = '重新获取'
          clearInterval(that.timer)
        }
        this.counterTimer--
      }, 1000)
    },
    toggleShowPassword () {
      this.showPassword = !this.showPassword
    },
    prevStep () {
      if (this.registerTitle === '注册') {
        if (this.judgmentModel === 'Android') {
          // eslint-disable-next-line
          LoginData.onBack()
        }
        if (this.judgmentModel === 'ios') {
          // eslint-disable-next-line
          onBack()
        }
      }
      if (this.registerTitle === '返回修改注册信息') {
        this.userinfoRegister = !this.userinfoRegister
        this.registerTitle = '注册'
      }
    },
    nextStep () {
      if (!this.userinfo.username.trim()) {
        this.rules.username = '姓名不能为空'
        const toast = this.$createToast({
          txt: `${this.rules.username}`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      } else {
        this.rules.username = ''
      }
      if (!this.userinfo.mobilePhone.trim()) {
        this.rules.mobilePhone = '手机号不能为空'
        const toast = this.$createToast({
          txt: `${this.rules.mobilePhone}`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!/^1\d{10}$/.test(this.userinfo.mobilePhone.trim())) {
        this.rules.mobilePhone = '请正确输入11位数字的手机号'
        const toast = this.$createToast({
          txt: `${this.rules.mobilePhone}`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!this.userinfo.vcode.trim()) {
        this.rules.vcode = '验证码不能为空'
        const toast = this.$createToast({
          txt: `${this.rules.vcode}`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (this.userinfo.vcode.trim().length < 6) {
        this.rules.vcode = '验证码不正确'
        const toast = this.$createToast({
          txt: `${this.rules.vcode}`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!this.userinfo.password.trim()) {
        this.rules.password = '密码不能为空'
        const toast = this.$createToast({
          txt: `${this.rules.password}`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (this.userinfo.password.trim().length < 6) {
        this.rules.password = '密码不能低于6位'
        const toast = this.$createToast({
          txt: `${this.rules.password}`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (this.sending) {
        return
      }
      let params = {
        phone: this.userinfo.mobilePhone,
        vcode: this.userinfo.vcode
      }
      this.sending = true
      checkVcode(JSON.stringify(params)).then((res) => {
        this.sending = false
        let { result, desc } = res.data
        if (result !== 0) {
          const toast = this.$createToast({
            txt: `${desc}`,
            type: 'error',
            time: 2000
          })
          toast.show()
          return
        }
        this.userinfoRegister = !this.userinfoRegister
        this.registerTitle = '返回修改注册信息'
      })
    },
    submitted () {
      if (!this.userinfo.garageName.trim()) {
        this.rules.garageName = '门店名称不能为空'
        const toast = this.$createToast({
          txt: `${this.rules.garageName}`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (this.selectedDistrict === '请选择地区') {
        const toast = this.$createToast({
          txt: `选择地区不能为空`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!this.userinfo.detailedAddress.trim()) {
        this.rules.detailedAddress = '详细地址不能为空'
        const toast = this.$createToast({
          txt: `${this.rules.detailedAddress}`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (this.sending) {
        return
      }
      let arr = this.selectedDistrict.split('/')
      this.userinfo.address.province = arr[0]
      if (arr[1]) {
        this.userinfo.address.city = arr[1]
      } else {
        this.userinfo.address.city = arr[0]
      }
      if (arr[2]) {
        this.userinfo.address.district = arr[2]
      } else if (arr[1]) {
        this.userinfo.address.district = arr[1]
      } else {
        this.userinfo.address.district = arr[0]
      }
      let params = {
        name: this.userinfo.username,
        phone: this.userinfo.mobilePhone,
        garageName: this.userinfo.garageName,
        passWord: md5(this.userinfo.password),
        province: this.userinfo.address.province,
        city: this.userinfo.address.city,
        district: this.userinfo.address.district,
        address: this.userinfo.detailedAddress,
        managerUserId: this.managerUserId || null
      }
      this.sending = true
      garageRegistered(JSON.stringify(params)).then((res) => {
        this.sending = false
        let { result, desc } = res.data
        if (result !== 0) {
          const toast = this.$createToast({
            txt: `${desc}`,
            type: 'error',
            time: 2000
          })
          toast.show()
          return
        }
        const toast = this.$createToast({
          txt: '注册成功',
          type: 'correct',
          time: 1000
        })
        toast.show()
        setTimeout(() => {
          if (this.judgmentModel === 'Android') {
            // eslint-disable-next-line
            LoginData.setData()
          } else if (this.judgmentModel === 'ios') {
            // eslint-disable-next-line
            setData()
          }
          if (this.managerUserId) {
            this.$router.push({ name: '注册', query: { managerUserId: this.managerUserId, time: Date.parse(new Date()) } })
            window.location.reload()
          }
        }, 1000)
      })
    },
    showCityPicker () {
      this.districtPickerVisible = true
      this.cityPicker.show()
    }
  },
  watch: {
    selectedDistrict () {
      if (this.selectedDistrict !== '请选择地区') {
        this.selectedDistrictVisible = true
      } else {
        this.selectedDistrictVisible = false
      }
    }
  },
  mounted () {
    this.initData()

    // 关闭开放注册
    if (!this.managerUserId) {
      let self = this
      const dialog = this.$createDialog({
        type: 'alert',
        confirmBtn: {
          text: '我知道了',
          active: true
        }
      }, (createElement) => {
        return [
          createElement('div', {
            'class': {
              'close-resigter-content': true
            },
            slot: 'content'
          }, [
            createElement('p', '抱歉，注册通道暂时关闭'),
            createElement('p', '请联系客服 400-921-8869'),
            createElement('p', '（周一至周五 9:00-18:00）'),
            createElement('span', {
              'class': {
                'known-btn': true
              },
              on: {
                click: function (e) {
                  if (!self.judgmentModel) {
                    return
                  }
                  self.prevStep()
                  dialog.close()
                }
              }
            }, '我知道啦')
          ])
        ]
      }).show()

      return
    }
    this.cityPicker = this.$createCascadePicker({
      title: '请选择地区',
      data: regionData,
      alias: {
        text: 'label'
      },
      onSelect: (selectedVal, selectedIndex, selectedText) => {
        this.districtPickerVisible = false
        this.selectedDistrict = selectedText.join('/')
      },
      onCancel: () => {
        this.districtPickerVisible = false
      }
    })

    let picker = document.getElementsByClassName('cube-picker-choose')
    picker[0].classList.add('area-picker')
  }
}
</script>

<style lang="scss" scoped>
@import "~styles/index.scss";
.main-body {
  background: #fff;
  font-size: px2rem(32px);
  // width: 100%;
  box-sizing: border-box;
  .header-wrap {
    padding: px2rem(18px) 0 px2rem(20px) px2rem(30px);
    box-sizing: border-box;
    // background: #f60;
    // font-size: px2rem(32px);
    width: 100%;
    box-sizing: border-box;
    .header {
      height: px2rem(50px);
      line-height: px2rem(50px);
      position: relative;
      .return {
        width: px2rem(40px);
        height: px2rem(28px);
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }
      .title {
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: px2rem(50px);
        font-size: px2rem(36px);
      }
    }
  }
  .register-banner img {
    width: px2rem(750px);
    height: px2rem(260px);
    font-size: px2rem(20px);
  }
  .register-wrap {
    padding: 0 px2rem(90px);
    // width: 100%;
    // box-sizing: border-box;
    // background: #f00;
    .register-list {
      background: #fff;
      margin-top: px2rem(90px);
      .li-title {
        border: none;
      }
      & li {
        padding: px2rem(35px) 0 px2rem(30px) 0;
        box-sizing: border-box;
        border-bottom: px2rem(1.2px) solid #e4e4e4;
        font-size: 0;
        width: px2rem(570px);
        .title {
          width: 100%;
          color: #363636;
          font-size: px2rem(36px);
          letter-spacing: px2rem(2px);
          font-weight: 600;
        }
        & span {
          display: inline-block;
          // width: 23%;
          width: px2rem(132px);
          height: px2rem(60px);
          line-height: px2rem(60px);
          border: none;
          font-size: px2rem(32px);
          color: $color-text;
          // background:#4081d6;
          box-sizing: border-box;
        }
        & :nth-child(2) {
          display: inline-block;
          width: px2rem(400px);
          margin-left: px2rem(30px);
          font-size: px2rem(32px);
        }
        .input-vcode {
          display: inline-block;
          width: px2rem(224px);
          margin-left: px2rem(30px);
          font-size: px2rem(32px);
        }
        .input-password {
          display: inline-block;
          width: px2rem(360px);
          margin-left: px2rem(30px);
          font-size: px2rem(32px);
        }
        .get-code {
          display: inline-block;
          width: 100%;
          height: px2rem(45px);
          font-size: px2rem(32px);
          font-family: PingFangSC-Regular;
          color: rgba(64, 129, 214, 1);
        }
        .after-get-code {
          font-size: px2rem(32px);
          width: 100%;
          font-family: PingFangSC-Regular;
          color: rgba(64, 129, 214, 1);
        }
        .eye-box {
          display: inline-block;
          width: px2rem(40px);
          height: px2rem(26.2px);
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(0, -50%);
          .open-eye {
            display: inline-block;
            width: px2rem(40px);
            height: px2rem(26.2px);
            background: url("~images/dow-ceremony/open-eye.png") center
              no-repeat;
            background-size: 100%;
          }
          .close-eye {
            display: inline-block;
            width: px2rem(40px);
            height: px2rem(26.2px);
            background: url("~images/dow-ceremony/close-eye.png") center
              no-repeat;
            background-size: 100%;
          }
        }
        .icon-selected-district {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(0, -50%);
        }
        .selected-district {
          display: inline-block;
          width: px2rem(338px);
          font-size: px2rem(32px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .unselected-district {
          color: #cccccc;
        }
        .bottom-arrow {
          display: inline-block;
          width: px2rem(24px);
          height: px2rem(12px);
        }
        .top-arrow {
          display: inline-block;
          width: px2rem(24px);
          height: px2rem(12px);
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
        }
      }
      & li:nth-child(1) {
        padding-top: 0;
      }
      .li-password {
        position: relative;
      }
      .li-selected-district {
        position: relative;
      }
    }
    .register-button {
      padding-top: px2rem(60px);
      margin-top: px2rem(60px);
      text-align: center;
      font-size: 0;
      .btn-default {
        span {
          font-size: px2rem(32px);
        }
      }
      .remark {
        margin-top: px2rem(34px);
        height: px2rem(40px);
        line-height: px2rem(40px);
        display: inline-block;
        font-size: px2rem(28px);
        color: #bbbbbb;
      }
    }
  }
}
</style>
